
import { HoloportVerificationResults } from "@/data/api-calls";
import { computed, defineComponent, PropType } from "vue";
import HoloForumLink from "./holo-forum-link.vue";

//Describes the direction of messaging to the user after HoloportIds were checked
export enum VerificationPath {
  NothingYet = 0,
  AllGood = 1,
  HasErrors = 2,
}

export default defineComponent({
  name: "PostSubmit",
  components: { HoloForumLink },
  props: {
    verificationResults: {
      type: Object as PropType<HoloportVerificationResults>,
    },
  },
  setup(props) {
    const checkedVerificationPath = computed(() => {
      const verificationResults = props.verificationResults;
      if (verificationResults == null) {
        return VerificationPath.NothingYet;
      }

      if (
        verificationResults.holoports.some((hp) => !hp.uptime) ||
        verificationResults.holoports.some((hp) => !hp.ssh)
      ) {
        return VerificationPath.HasErrors;
      }

      return VerificationPath.AllGood;
    });

    const anyFailingUptime = computed(() => {
      const verificationResults = props.verificationResults;
      if (
        verificationResults != null &&
        verificationResults.holoports.some((hp) => !hp.uptime)
      ) {
        return true;
      }

      return false;
    });

    const anyFailingSsh = computed(() => {
      const verificationResults = props.verificationResults;
      if (
        verificationResults != null &&
        verificationResults.holoports.some((hp) => hp.uptime && !hp.ssh)
      ) {
        return true;
      }

      return false;
    });

    const holoportsWithIssues = computed(() => {
      if (props.verificationResults == null) {
        return [];
      }

      return props.verificationResults.holoports
        .filter((hp) => !hp.uptime || !hp.ssh)
        .map((hp) => ({ shortId: hp.id?.slice(-8), ...hp }));
    });

    return {
      VerificationPath,
      checkedVerificationPath,
      holoportsWithIssues,
      anyFailingUptime,
      anyFailingSsh,
    };
  },
});
