
import { defineComponent } from 'vue';
import InviteForm from "../components/holo-alpha/holo-alpha-registration-form.vue";

export default defineComponent({
  name: 'HoloAlpha',
  components: {
    InviteForm
  },
  created() {
    document.title = "HoloPort Alpha Program"
  }
});
