
import { computed, defineComponent, ref } from 'vue';
import TextField from "./text-field.vue";
import ParticipationField from "./participation-field.vue";
import HoloportIdsField from "./holoport-ids-field.vue";
import SubmitControls from "./submit-controls.vue";
import PostSubmit from "./holo-alpha-post-submit.vue";
import { HoloportVerificationResults, updateHoloAlphaRegistration, verifyHoloports } from "../data/api-calls";
import { HoloportIdentifier } from "../types";

function later(delay: number) {
    return new Promise(function(resolve) {
        setTimeout(resolve, delay);
    });
}

export default defineComponent({
    name: 'AdditionalDataForm',
    props: {
        holoAlphaRegistrationId: {
            type: String,
            required: true
        }
    },
    components: { TextField, ParticipationField, HoloportIdsField, PostSubmit, SubmitControls },
    setup(props) {

        const stage = ref("gather");

        const holoForumHandle = ref("");
        const holoportIds = ref<HoloportIdentifier[]>([{ id: "" }]);
        const participationType = ref("");
        const verifyResults = ref<HoloportVerificationResults | null>(null);

        const inputIsReady = computed(() => {
            const handleReady = holoForumHandle.value.trim() !== "";
            const holoportIdReady = holoportIds.value.length > 0 &&  holoportIds.value[0].id.trim() !== "";
            return handleReady && holoportIdReady;
        });

        /** Extract the id value and clean it */
        const cleanHoloportIdValue = (holoportId: HoloportIdentifier): string => {
            let id = holoportId.id;
            
            //Sometimes users will put in the whole holoport url. Extract the holoport Id from that
            id = id.indexOf('.holohost.net') !== -1 ? id.slice(0, id.indexOf('.holohost.net')) : id;
            id = id.replace('http://', '');
            id = id.replace('https://', '');
            
            return id.trim();
        };

        const submitHandler = async () => {

            const cleanedPortIdValues = holoportIds.value.map(cleanHoloportIdValue).filter((holoportId) => holoportId !== '');
            
            verifyResults.value = await verifyHoloports(cleanedPortIdValues);
            //console.log(verifyResults.value);

            const submitResult = await updateHoloAlphaRegistration({
                holoAlphaRegistrationId: props.holoAlphaRegistrationId,
                holoForumHandle: holoForumHandle.value,
                holoportIds: verifyResults.value.holoports.map((hp) => ({ id: hp.id, uptimeResult: hp.uptime, sshResult: hp.ssh })),
                participationType: participationType.value
            });

            await later(1000);
            stage.value = "";
            
            await later(1000);
            stage.value = "submited";

        }

        return {
            stage, holoForumHandle, holoportIds, participationType,
            inputIsReady, verifyResults, submitHandler
        };
    },
    beforeMount() {
        document.body.classList.add("additional-data");
    },
    unmounted() {
        document.body.classList.remove("additional-data");
    }
})

