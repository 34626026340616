
import { defineComponent, onMounted, ref } from 'vue';
import BadOrExpiredCode from "../components/bad-or-expired-code.vue";
import AdditionalDataForm from "../components/holo-alpha-additional-data.vue";
import HoloForumLink from "../components/holo-forum-link.vue";
import { confirmOneTimeLink } from "../data/api-calls";

export default defineComponent({
  name: 'OneTimeLink',
  components: { BadOrExpiredCode, AdditionalDataForm, HoloForumLink },
  props: {
    otlCode: {
      type: String,
      required: true
    }
  },
  setup(props: any) {

    const otlCodeExists = ref<boolean | null>(null);
    const otlCodeStillValid = ref<boolean | null>(null);
    const holoAlphaRegistrationId = ref("");

    onMounted(async () => {
      const otlResponse = await confirmOneTimeLink(props.otlCode)

      otlCodeExists.value = otlResponse.codeGood;
      //The and op is probably unneeded, but potentially safer
      otlCodeStillValid.value = otlResponse.codeGood && !otlResponse.codeExpired;
      holoAlphaRegistrationId.value = otlResponse.id || "";
    });

    return {
      otlCodeExists, otlCodeStillValid, holoAlphaRegistrationId
    }
  }
});
