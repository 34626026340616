
import { defineComponent } from 'vue';

const baseUrl = "https://forum.holo.host";

export default defineComponent({
    name: 'HoloForumLink',
    props: {
        relativePath: {
            type: String,
            required: false,
            default: null
        }
    },
    computed: {
        linkUrl(): string {
            if (this.relativePath) {
                return `${baseUrl}/${this.relativePath}`;
            } else {
                return baseUrl;
            }
        }
    }
});

