
import { defineComponent } from 'vue';

import axios from 'axios';

import TextField from "../text-field.vue";
import LegalJuristictionField from "./holo-alpha-juristiction-field.vue";
import SubmitControls from "./holo-alpha-submit-controls.vue";
import PostSubmit from "./holo-alpha-post-submit.vue";

function later(delay: number) {
    return new Promise(function(resolve) {
        setTimeout(resolve, delay);
    });
}

interface ComponentData {
    fullName: string;
    email: string;
    legalJurisdiction: string;
    role: string;
    stage: string;
}

export default defineComponent({
    name: 'InviteForm',
    components: { TextField, LegalJuristictionField, SubmitControls, PostSubmit },
    data(): ComponentData {
        return {
            fullName: "",
            email: "",
            legalJurisdiction: "",
            role: "host",
            stage: "gather"
        }
    },
    computed: {
        inputIsReady(): boolean {
            return this.fullName.trim() !== "" &&
                    this.legalJurisdiction.trim() !== "" &&
                    //this.role !== "" &&
                    /\S+@\S+/.test(this.email);
        }
    },
    methods: {
        async submitHandler() {
            const payload = {
                fullName: this.fullName,
                email: this.email,
                legalJurisdiction: this.legalJurisdiction,
                app: "Alpha",
                role: this.role || "unknown"
            }

            //console.log("submitApproved", payload);

            //Submit via ajax
            const response = await axios({
                method: 'post',
                url: "/alpha",
                data: payload
            });

            //console.log(response)

            await later(4000);
            this.stage="";
            
            await later(1000);
            this.stage="submited";
        }
    },
    beforeMount() {
        document.body.classList.add("holo-alpha");
    },
    unmounted() {
        document.body.classList.remove("holo-alpha");
    }
});
