
import { defineComponent, ref, PropType } from 'vue';
import { HoloportIdentifier } from "../types";

export default defineComponent({
    name: 'HoloportIdsField',
    props: {
        fieldId: String,
        labelText: String,
        modelValue: Array as PropType<Array<HoloportIdentifier>>,
        inputType: String,
        placeholder: String,
    },
    emits: [ 'update:modelValue' ],
    setup(props) {
        const multiline = ref(false);
        
        function makeMultiline() {
            multiline.value = true;
        }

        return {
            multiline, makeMultiline
        };
    },
    methods: {
        processIdsValue(rawValue: string) {
            const splitVals = rawValue.split("\n");
            
            this.$emit('update:modelValue', splitVals.map((item) => ({ id: item })));
        }
    },
    computed: {
        singleLineStringVal(): string {
            return this.modelValue?.map((item) => item.id).join(", ") || "";
        },
        
        multiLineStringVal(): string {
            return this.modelValue?.map((item) => item.id).join("\n") || "";
        },
    }
});
