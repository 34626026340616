import { HoloportIdentifier } from "../types";
import axios from "axios";

export interface ConfirmOneTimeLinkReturn { 
    codeGood: boolean; 
    codeExpired: boolean; 
    id?: string; 
}

export const confirmOneTimeLink = async (otlCode: string): Promise<ConfirmOneTimeLinkReturn> => {
    const result = await axios({
        url: `/api/init-secondary/${otlCode}`,
        method: "GET"
    });

    //console.log(result);

    return result.data;
};

export interface HoloportVerification {
    id: string;
    uptime: boolean;
    ssh: boolean;
}

export interface HoloportVerificationResults {
    holoports: HoloportVerification[];
}

export const verifyHoloports = async (holoportIds: string[]): Promise<HoloportVerificationResults> => {
    const result = await axios({
        url: `/api/verify-holoports`,
        method: "POST",
        data: { holoportIds }
    });

    //console.log(result);

    return result.data;
}

export const checkHoloportHostStats = async (holoportId: string): Promise<any> => {
    const result = await axios({
        url: `/api/check-holoport-host-stats/${holoportId}`,
        method: "GET"
    });

    return result.data;
}

export interface HoloAlphaRegistrationPayload {
    holoAlphaRegistrationId: string;
    holoForumHandle: string;
    holoportIds: HoloportIdentifier[];
    participationType: string;
}

export const updateHoloAlphaRegistration = async (data: HoloAlphaRegistrationPayload): Promise<any> => {
    console.log(data);

    const result = await axios({
        url: '/api/update',
        method: 'POST',
        data
    });

    return result.data;
};