
import dragVerify from "../drag-verify.vue";
import { defineComponent, ref, onMounted, onUnmounted } from 'vue';

export default defineComponent({
    name: 'SubmitControls',
    props: {
        enabled: Boolean
    },
    components: { dragVerify },
    setup() {
        const container = ref();
        const dragVerifyWidth = ref(300);

        const calculateDragVerifyWidth = function() {
            dragVerifyWidth.value = Math.min(container.value.clientWidth * 0.9, 400);
        };

        onMounted(() => {
            calculateDragVerifyWidth();
            window.addEventListener("resize", calculateDragVerifyWidth)
        }),
        onUnmounted(() => {
            window.removeEventListener("resize", calculateDragVerifyWidth);
        })

        return {
            container,
            dragVerifyWidth
        }
    },
    methods: {
        passcallbackHandler() {
            this.$emit("submit-approved");
        }
    },
    computed: {
        showSubmit(): boolean {
            return this.enabled;
        }
    }
})
