
import { computed, defineComponent, ref } from 'vue';

export default defineComponent({
    name: 'BadOrExpiredCode',
    
    beforeMount() {
        document.body.classList.add("additional-data");
    },
    unmounted() {
        document.body.classList.remove("additional-data");
    }
})

