
import { defineComponent } from 'vue';

import { jurisdictions } from "../legal-juristiction-data";

export default defineComponent({
    name: 'LegalJuristictionField',
    props: ['fieldId', 'labelText', 'modelValue'],
    data() {
        return {
            jurisdictions
        }
    }
});
